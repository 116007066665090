<template>
  <div id="add-event">
    <h1>{{ $t("QUESTION.ADD_QUESTION") }}</h1>
    <form class="mt-5">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="event-name">{{ $t("QUESTION.QUESTION") }}</label>
            <input
              type="text"
              class="form-control"
              id="question"
              :placeholder="$t('QUESTION.QUESTION')"
              v-model="question.question"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="event-location">{{ $t("QUESTION.TYPE") }}</label>
            <b-form-select
              v-model="question.type"
              :options="options"
            ></b-form-select>
          </div>
        </div>
      </div>
      <template v-if="question.type === 1">
        <div class="row">
          <div class="col-5">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('QUESTION.ANSWER')"
              v-model="option.text"
            />
          </div>

          <div class="col-2">
            <b-button @click="addOption" class="btn-success">{{
              $t("COMMON.ADD")
            }}</b-button>
          </div>
        </div>
        <div v-if="question.options.length > 0" class="mt-4">
          <h5 class="mb-4">{{ $t("QUESTION.OPTIONS") }}</h5>
          <div
            class="option-tag"
            v-for="(opt, idx) in question.options"
            :key="idx"
          >
            {{ opt.text }}
          </div>
        </div>
      </template>
      <div class="d-flex justify-content-center mt-3">
        <button
          :disabled="isDisabled"
          @click.prevent="onSubmit"
          class="btn btn-primary"
        >
          {{ $t("COMMON.SUBMIT") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      question: {
        question: null,
        type: null,
        options: [],
      },
      option: {
        text: null,
      },
      loading: false,
      options: [
        { value: null, text: "Select an option" },
        { value: 0, text: "Text" },
        { value: 1, text: "MCQ" },
      ],
      optionsCount: 0,
    };
  },
  computed: {
    isDisabled() {
      if (
        this.question.question === null ||
        this.question.question === "" ||
        this.question.type === null
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      saveQuestion: "saveQuestion",
    }),
    onSubmit() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const question = {
        question: this.question.question,
        type: this.question.type,
        options: this.question.type === 0 ? [] : this.question.options,
      };

      this.saveQuestion({
        vm: this,
        data: {
          question,
        },
      });
    },
    resetFields() {
      this.question = {
        question: null,
        type: null,
        options: [],
      };
    },
    addOption() {
      this.option.value = this.optionsCount++;
      this.question.options.push(this.option);
      this.option = {
        value: null,
        text: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#add-event {
  background: #fff;
  padding: 20px;

  h1 {
    font-size: 20px;
    color: #3c4eba;
    font-weight: 500;
  }

  h5 {
    font-size: 12px;
    color: #1faeae;
    font-weight: 400;
  }

  .option-tag {
    background-color: lightblue;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 10px;
    display: inline;
    margin-right: 10px;
    color: blueviolet;
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
